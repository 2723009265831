// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeLogo,
    placeShuffle,
    placeTest
} from '../../../common/edliy_utils-geometric';
const Boxes = {
box1: function () {
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 14, 8, -2],
    keepaspectratio: true, axis:true, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.options.layer['image']=14;
brd1.suspendUpdate();
//Title
makeResponsive(brd1);
placeTitle(brd1, 'Definite Integral as Limit of Sum');
placeLogo(brd1);
var shuffle = placeShuffle(brd1);
var test = placeTest(brd1);
shuffle.setLabel('Press to Shuffle');
shuffle.label.setAttribute({visible:false, offset:[-15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
shuffle.on('over', function () {this.label.setAttribute({visible:true});});
shuffle.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////
test.setLabel('Press to Test Your Answer');
test.label.setAttribute({visible:false, offset:[-15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
test.on('over', function () {this.label.setAttribute({visible:true});});
test.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////////////////////////
//Sliders
var track=brd1.create('segment', [[0,0],[10, 0]],{visible:false});
var ptB = placeGlider(brd1, track, 2, 0, 6, 'red', 'black');
ptB.setAttribute({face:'^'});
var ptA = placePoint(brd1, 0, 0, 4, 'white', 'black');
ptA.setAttribute({face:'square', fixed:true});
var nRectangles = placeSlider(brd1, -6, 6, 10, 10, 200, 3, 'N');
//brd1.create('slider',[[5,10],[8,10],[10,10,100]], {snapWidth: 1, snapToGrid: false ,face:'circle', size:3, name:'n', strokeColor:'black', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
nRectangles.setAttribute({fillColor:'red', snapWidth:1});
//var aValue = placeSlider(brd1, -6,5, 0,0, 10, 3, 'a');
//aValue.setAttribute({fillColor:'blue', snapWidth:0.25, visible:false});
//var bValue =  placeSlider(brd1, -6,4,1,3,  10, 3, 'b');
//bValue.setAttribute({fillColor:'green', snapWidth:0.25});
//
var texta = placeText(brd1, 0, 0, 'x=a');
texta.setAttribute({anchorY:'top', color:'blue'});

var textb = placeText(brd1, ()=>ptB.X(), 0, 'x=b');
textb.setAttribute({anchorY:'top', color:'green'});
//Function f(x) = 0.5*x*x-2*x.
var f = function(x) { return 0.5*x*x+2; };
//
var g = brd1.create('functiongraph',[f, 0, 5], {strokeColor:'red', strokeWidth:()=>4*brd1.canvasHeight/800});
//Upper Riemann Sum
var upper = brd1.create('riemannsum',
            [f, function(){return nRectangles.Value();}, 'upper', 0, ()=>ptB.X()],
            {fillOpacity:0.4, fillColor: 'blue', visible:false, strokeColor:'black'}
            );
//Lower Reimann Sum
var lower = brd1.create('riemannsum',
[f, function(){return nRectangles.Value();}, 'lower', 0, ()=>ptB.X()],
{fillOpacity:1., fillColor: 'green', strokeColor:'black', visible:false}
);
//Integral Value
var integral = brd1.create('integral',
[[ ()=>ptA.X(), ()=>ptB.X()], g],
{highlight:false, strokeColor:'black',fillColor: 'grey',
visible: true, label:{anchorX:'left', precision:2, anchorY:'bottom', highlight:false}});
//Texts
var upperTxt = brd1.create('text',[-2,10, function(){ return ' = ' + JXG.toFixed(upper.Value(), 2); }],{visible:false, anchorX:'left',fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}},);
var lowerTxt = brd1.create('text',[-2,9, function(){ return ' = ' + JXG.toFixed(lower.Value(), 2); }],{visible:false, anchorX:'left',fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}},);
var integralTxt = brd1.create('text',[-2,8, function(){ return ' = ' + JXG.toFixed(integral.Value(), 2); }],{visible:true, anchorX:'left', fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}},);

//Checkbox Upper
var checkboxUpper = brd1.create('checkbox', [-6, 10, '  Upper Sum Approximation'], {checked:false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});

JXG.addEvent(checkboxUpper.rendNodeCheckbox, 'change', function() {
    if (this.Value()) {
        upper.setAttribute({visible: true});
        upperTxt.setAttribute({visible: true});
    } else {
        upper.setAttribute({visible: false});
        upperTxt.setAttribute({visible: false});


    }
}, checkboxUpper);

//Checkbox Lower

var checkboxLower = brd1.create('checkbox', [-6, 9, ' Lower Sum Approximation'], {checked:false,
  fixed: true, fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}});

JXG.addEvent(checkboxLower.rendNodeCheckbox, 'change', function() {
    if (this.Value()) {
        lower.setAttribute({visible: true});
        lowerTxt.setAttribute({visible: true});
    } else {
        lower.setAttribute({visible: false});
        lowerTxt.setAttribute({visible: false});

    }
}, checkboxLower);

//Checkbox Integral

var checkboxIntegral = brd1.create('checkbox', [-6, 8, '  Integral Value (a to b) '], {checked:true,
  fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}});

JXG.addEvent(checkboxIntegral.rendNodeCheckbox, 'change', function() {
    if (this.Value()) {
        integral.setAttribute({visible: true});
        integralTxt.setAttribute({visible: true});
    } else {
        integral.setAttribute({visible: false});
        integralTxt.setAttribute({visible: false});

    }
}, checkboxIntegral);

//Shuffle image
var shuffle1 = brd1.create('image', ['/assets/shuffle.svg', [-1, -6], [1.5, 1.5]], {visible: true, fixed: true});
shuffle1.setLabel('Shuffle')
shuffle1.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
shuffle1.on('over', function () {this.label.setAttribute({visible:true});});
shuffle1.on('out', function () {this.label.setAttribute({visible:false});});

//Shuffle function
shuffle1.on('down',function(){});


//REDO AND UNDO
var redo = brd1.create('image', ['/assets/redo.svg', [1, -6], [1.5 , 1.5]], {visible: true, fixed: true});
var undo = brd1.create('image', ['/assets/undo.svg', [function(){return redo.X()-4}, function(){return redo.Y()}], [1.5 , 1.5]], {visible: true, fixed: true});

redo.setLabel('Redo')
redo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
redo.on('over', function () {this.label.setAttribute({visible:true});});
redo.on('out', function () {this.label.setAttribute({visible:false});});

undo.setLabel('undo')
undo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
undo.on('over', function () {this.label.setAttribute({visible:true});});
undo.on('out', function () {this.label.setAttribute({visible:false});});

var i =0;
var maxSteps = 1;

function updateSteps(){
    eraseEverything();

    if (i<=0){


    }
    if(i==1){

    }
}

function eraseEverything(){


}

redo.on('down',function(){
    i = i+1;
    if (i > maxSteps){
        i = maxSteps;
    }
    updateSteps();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    eraseEverything();
    updateSteps();
});

brd1.unsuspendUpdate();
}
}
export default Boxes;
