<template>
  <div>
    <h3 ref="def">
      Definite Integral
    </h3>
    <p>
      Definite integral of a function, say \(f(x)\), over a domain \(x \in [a, b]\) represents the area
      under the curve representing \(f(x)\).
    </p>

    <h3 ref="def">
      Definite Integral as a Limit of Sum
    </h3>
    <p> The area under a curve can be approximated by adding the areas of rectangles. </p>

    <h5>To Get Started</h5>
    <p>
      This MagicGraph shows the curves representing function \(f(x)\) and its derivative \(f'(x)\).
      The bright RED point is a draggable point that can glide on the curve representing function \(f(x)\).
      Dragging the RED point will make the bright BLUE point glide along the curve representing \(f'(x)\).
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'DefiniteIntegral',
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'Definite Integral';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Square Formula', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Definite Integral',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about square formula'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
